<template>
	<a-modal okText="确定" cancelText="取消" v-model="visibles" title="选择图片" @ok="checkedpic">
		<div class="content">
			<div class="resources">
				<!-- 头部 -->
				<a-card class="acard f-acjsb">
					<div class="f-acjsb">
						<div class="litem f-ac">
							<div class="handleitem">
								<a-icon class="aicon" type="appstore" />
							</div>
							<div class="handleitem">
								<a-icon class="aicon" type="bars" />
							</div>
							<!-- <div class="handleitem">
								<a-input-search placeholder="input search text" enter-button />
							</div> -->
						</div>
						<div class="ritem f-ac">
							<div style="padding-right: 0;" class="handleitem" @click="uploadimg">
								<a-upload
								  name="iFile"
								  :accept="accept"
								  :beforeUpload="beforeUpload"
								  :customRequest="customRequest"
								  :multiple="true"
								  :showUploadList="false"
								>
								  <a-button icon="cloud-upload">上传</a-button>
								</a-upload>
							</div>
						</div>
					</div>
				</a-card>
				
				<!-- 图片区域 -->
				<div class="picarea mt-30 ">
					
					<div class="mt-30 ml-30 f-ac">
						<div class="title">
							{{filenames}}
						</div>
						<div class="btn">
							<a-button class="ml-15" type="primary" @click="goback">
								上级目录
							</a-button>
						</div>
					</div>
					<!-- 图片列表 -->
					<div class="filewrap f-ac mt-30">
						<template v-if="folderlist.length>0">
							<template v-for="(item,index) in folderlist">
								<!-- 判断是否是文件类型 -->
								<template v-if="item.type == 1">
									<div class="fleitem mb-15 ml-15"  @dblclick="gofolder(item)">
										<div :class="[item.ischecked?'fileblock-active':'fileblock' ,'f-ajc' ]">
											<a-icon class="aicon" type="folder-open" />
											<div class="positon" >
												<template v-if="item.ischecked">
													<a-checkbox :default-checked="item.ischecked" @change="onChangecheck"></a-checkbox>
												</template>
											</div>
										</div>
										<div class="txt mt-15">
											<template v-if="isrename">
												<a-input ref="input" v-model="item.rename" auto-focus @blur="comfirmname" />
											</template>
											<template v-else>
												<span>{{item.name}}</span>
											</template>
										</div>
									</div>
								</template>
								
								<!-- 图片类型 -->
								<template v-else>
									<div class="fleitem mb-15 ml-15" @click="checkfolder(item)">
										<div :class="[item.ischecked?'fileblock-active':'fileblock' ,'f-ajc' ]">
											<!-- <a-icon class="aicon" type="folder-open" /> -->
											<img style="width: 100%;height: 100%;" :src="item.path" alt="">
											<div class="positon" @click.stop="tapcheck(item)">
												<template v-if="item.ischecked">
													<a-checkbox :default-checked="item.ischecked" @change="onChangecheck"></a-checkbox>
												</template>
											</div>
										</div>
										<div class="txt single_txtover mt-15">
											<template v-if="isrename">
												<a-input ref="input" v-model="item.rename" auto-focus @blur="comfirmname" />
											</template>
											<template v-else>
												<span>{{item.name+'.'+item.suffix}}</span>
											</template>
										</div>
									</div>
								</template>
							</template>
						</template>
						<template v-else>
							<div class="nothing" >
								<a-empty />
							</div>
						</template>
					</div>
				</div>
				
				<!-- 图片上传 -->
				<!-- <a-modal okText="确定" cancelText="取消" v-model="visible" title="上传图片" @ok="finshimg">
					<div class="menu">
						<a-upload-dragger name="file" accept="image/png,image/gif,image/jpeg" :multiple="true"
							:customRequest="customRequest" :beforeUpload="beforeUpload" @change="handleChange">
							<p class="ant-upload-drag-icon">
								<a-icon type="inbox" />
							</p>
							<p class="ant-upload-text">
								将图片拖放到此处上传，或点击上传。
				
							</p>
							<p class="ant-upload-hint">
								为了保证图片的正常使用，仅支持3M以内的jpg、jpeg、gif、png格式图片上传。
								支持选择多张图片上传，支持拖拽文件夹上传。
							</p>
						</a-upload-dragger>
					</div>
				</a-modal> -->
				
				<!-- 新建文件夹 -->
				<a-modal okText="确定" cancelText="取消" v-model="newfile" title="新建文件夹" @ok="comfirmclass">
				
					<div class="filewrap mb-30">
						<div class="txt mb-15">
							分组名称
						</div>
						<a-input v-model="classname" placeholder="请输入分组名称 " />
					</div>
				</a-modal>
			</div>
		</div>
	</a-modal>
	
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapMutations,
		mapActions
	} from "vuex";
	export default {
		data() {
			return {
				visibles:false,
				visible: false,
				newfile: false,
				isrename: false,
				pid:0,  //当前id
				superior:0,//上级id
				classname: "" ,//分组名称
				folderlist:"",
				file_name:"",
				file_suffix:"",
				file:"",
				itempath:"",
				filelist:[],
				filenames:"根目录"//当前文件名
			}
		},
		created() {
			this.getfolderlist()
			console.log(this.isresources,"开关传递");
		},

		methods: {
			...mapActions({
				getupload: "resources/getupload",
				getcrefolder: "resources/getcrefolder",
				getlist:'resources/getlist',
				getdeletes:"resources/getdeletes"
			}),
			// 获取数据
			async getdata(payload){
				let data = await this.getlist(payload)
				console.log(data.data,"文件目录");
				
				data.data.map(item=>{
					item.ischecked =  false
				})
				this.folderlist = data.data
				this.superior = data.superior
			},
			// 获取文件目录
			getfolderlist(){
				let payload = {
					pid:this.pid
				}
				this.getdata(payload)
			},
			// 进入子文件夹
			gofolder(item) {
				this.pid = item.id
				let payload = {
					pid:item.id
				}
				this.getdata(payload)
			},
			// 返回上一级文件
			async goback(){
				if(this.superior == -1){
					this.$message.warning('已经是全部文件了！');
					return
				}
				this.pid = this.superior
				let payload = {
					pid:this.superior
				}
				this.getdata(payload)
			},
			// 选中文件夹或图片
			checkfolder(item){
				console.log(item,"选中文件夹");
				item.ischecked = !item.ischecked
				this.folderlist.map(items=>{
					if(items.id != item.id){
						items.ischecked = false
					}
					
				})
				this.itempath = item.path
			},
			checkedpic(){
				this.$emit("getimgpath",this.itempath)
				this.visibles = false
			},
			// 删除选中文件
			async deletefolder(){
				let data = this.folderlist.filter(item=>{
					return item.ischecked == true
				})
				console.log(data,"选中文件");
				// 获取选中id
				let resarr = [];
				data.map(item=>{
					resarr.push(item.id)	
				})
				 resarr =  resarr.join(",")
				 console.log(resarr,"i地址");
				let msg = await this.getdeletes(resarr)
				this.$message.success(msg);
				this.getfolderlist()
			},
			
			onChangecheck(e) {
				console.log("选择变化", e);

			},
			// 上传图片
			uploadimg() {
				this.visible = true
			},

			// 上传图片前校验
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
				if (!isJpgOrPng) {
					this.$message.error('只能上传jpg/png格式的头像!')
				}
				const isLt3M = file.size / 1024 / 1024 < 3
				if (!isLt3M) {
					this.$message.error('图片不得大于3MB!')
				}
				return isJpgOrPng && isLt3M
			},
			customRequest(file) {
				let that = this
				console.log(file, "自定上传");
				let reader = new FileReader();
				reader.readAsDataURL(file.file);
				reader.onload =  (e) => {
					this.filelist = []
					this.file_name = file.file.name
					this.file_suffix = file.file.type
					this.file = e.currentTarget.result
					let obj = {
						file_name : file.file.name,
						file_suffix : file.file.type,
						file : e.currentTarget.result
					}
					this.filelist.push(obj)
					this.finshimg()
				}
			},

			
			async finshimg(){
				console.log(this.filelist,"上传");
				for (var i = 0; i < this.filelist.length; i++) {
					let payload = {
						pid: this.pid,
						file_name: this.filelist[i].file_name,
						file_suffix: this.filelist[i].file_suffix,
						file: this.filelist[i].file,
					}
					let msg = await this.getupload(payload)
					this.$message.success(`创建成功.`);
				}
				this.filelist = []
				this.visible = false
				this.getfolderlist()
			},
			// 新建文件夹
			createfolder() {
				this.newfile = true
				this.classname  = ""
			},
			// 确认分组
			async comfirmclass(e){
				if(this.classname){
					let payload =  {
						pid:this.pid,
						name:this.classname
					}
				let msg = await this.getcrefolder(payload)
				this.$message.success(`创建成功.`);
				}else{
					this.$message.error(`请输入文件名.`);
				}
			},
			// 重命名
			renameing() {
				this.isrename = true
			},
			// 确定修改文件名
			comfirmname() {
				console.log("确定好了");
				this.isrename = false
			}
		},
	}
</script>

<style scoped lang="less">
	.resources{
		border: 1px solid @bg-color;
	}
	.nothing{
		width: 100%;
	}
	.acard {
		background: @bg-color;
	}

	.f-acjsb {
		width: 100%;
	}

	.handleitem {
		padding-right: 30px;
	}

	.aicon {
		font-size: 32px;
		color: @primary-color;
	}
	.filewrap{
		flex-wrap: wrap;
		margin-top: 0;
		margin-left: -15px;
		padding: 15px;
		// background: @bg-color;
	}
	.fleitem {
		margin-left: 21px;
		.fileblock {
			width: 68px;
			height: 68px;
			background: @bg-color;
			position: relative;
			border: 1px solid @bg-color;
			.aicon {
				font-size: 44px;
				// color: @primary-color;
			}

			.positon {
				position: absolute;
				top: 2px;
				left: 4px;
			}
		}

		.fileblock-active {
			width: 68px;
			height: 68px;
			background: @lessest-primary-color;
			border: 1px solid @less-primary-color;
			position: relative;

			.aicon {
				font-size: 44px;
				// color: @primary-color;
			}

			.positon {
				position: absolute;
				top: 2px;
				left: 4px;
			}
		}

		.txt {
			width: 68px;
			
			text-align: center;
		}
	}

	// 新建文件夹
	.filewrap {
		.txt {}
	}
</style>